import React from "react"
import styled from "@emotion/styled"
import { breakpoints } from "@dinotechltd/components"
import { Marks, List } from "."

const StyledH1 = styled.h1`
  font-size: 24px;
  margin: 24px 0px;
  ${breakpoints.md} {
    font-size: 32px;
  }
`

const StyledH2 = styled.h2`
  font-size: 50px;
  u {
    position: relative;
    text-decoration: none;
    font-size: 50px;
  }

  u::before {
    content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    /* left: 0; */
    bottom: 5px;
    /* top: 0; */
    width: 5000px;
    height: 55px;
    background-color: #ed55fa;
    z-index: -1;
  }
  ${breakpoints.md} {
    font-size: 50px;
  }
`

const StyledH3 = styled.h3`
  font-size: 24px;
  padding: 20px;
  ${breakpoints.md} {
    font-size: 20px;
  }
`

const StyledH4 = styled.h4`
  padding-top: 20px;
  font-size: 12px;
  ${breakpoints.md} {
    font-size: 16px;
  }
`
const StyledH5 = styled.h4`
  font-size: 12px;
  ${breakpoints.md} {
    font-size: 15px;
  }
`
const StyledH6 = styled.h4`
  font-size: 12px;
  ${breakpoints.md} {
    font-size: 14px;
  }
`

const renderText = (props: any) => {
  const { type, children } = props
  const text = children && children.map((child: any) => child.text)
  switch (type) {
    case "heading-one":
      return (
        <StyledH1>
          <Marks {...props} />
        </StyledH1>
      )
    case "heading-two":
      return (
        <StyledH2>
          <Marks {...props} />
        </StyledH2>
      )
    case "heading-three":
      return (
        <StyledH3>
          <Marks {...props} />
        </StyledH3>
      )
    case "heading-four":
      return (
        <StyledH4>
          <Marks {...props} />
        </StyledH4>
      )
    case "heading-five":
      return (
        <StyledH5>
          <Marks {...props} />
        </StyledH5>
      )
    case "heading-six":
      return (
        <StyledH6>
          <Marks {...props} />
        </StyledH6>
      )
    default:
      return (
        <p>
          <Marks {...props} />
          <List {...props} />
        </p>
      )
  }
}

const Text = (props: any) => {
  return renderText(props)
}

export default Text
