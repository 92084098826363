import React from "react"
import { richTextRenderer } from "@dinotechltd/gatsby"
import Cards from "./Cards"
import { Section } from "./Section"
import Shape from "./Shape"

// import loadable from "@loadable/component"

import ContentBlock from "./ContentBlock"
import { CookieDeclaration } from "@dinotechltd/components"
// import { renderMenuBlock, renderPageLinkBlock } from "./MenuBlock"

const Renderer = richTextRenderer({
  renderContentBlock: (props: any) => <ContentBlock {...props} />,
  renderCookieDeclarationBlock: () => <CookieDeclaration />,
  renderSeoBoxBlock: ({ body }) => {
    return <Renderer data={body} />
  },
  renderCardBlock: (props: any) => {
    return <Shape image={props} />
  },
  renderTextSectionBlock: (props: any) => {
    return <Section text={props} />
  },
  renderCardListBlock: (props: any) => {
    return <Cards cardList={props} />
  },
})

export default Renderer
