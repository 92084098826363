import React, { FC } from "react"
import { Text, Marks, List, Quote } from "../ContentBlock"

type Props = {
  content: {
    type: string
    nodes: Array<Object>
  }
}

const renderNode = (type: string, props: any) => {
  if (type) {
    switch (true) {
      case type.startsWith("heading-"):
        return <Text {...props} />
      case type === "paragraph":
        return <Marks {...props} />
      case type === "block-quote":
        return <Quote {...props} />
      case type.endsWith("-list"):
        return <List {...props} />
      default:
        return null
    }
  } else {
    return null
  }
}

export const Node: FC<Props> = ({ content }) => {
  const { type } = content
  return renderNode(type, content)
}
