/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC } from "react"
import Header from "./Header"
import Footer from "./Footer"

import "./layout.css"
import styled from "@emotion/styled"

import { colors } from "../styles/backgrounds"
import { fonts } from "../styles/fonts"

const LayoutStyle = styled.div``

const Content = styled.div`
  min-height: 100vh;
  max-width: 1250px;
  margin: 20px auto;

  @media (min-width: 2000px) {
    margin: 60px auto 550px;
  }
`

const Layout: FC = ({ children }) => {
  return (
    <LayoutStyle
      style={{
        color: fonts.fontColor,
        paddingTop: 0,
        backgroundColor: colors.backgroundColor,
        backgroundImage: `linear-gradient(to right, ${colors.headerColor}, ${colors.backgroundColor}, ${colors.headerColor})`,
      }}
    >
      <Header />
      <Content>{children}</Content>
      <Footer />
    </LayoutStyle>
  )
}
export default Layout
