import styled from "@emotion/styled"

export const CardTitle = styled.h2`
  height: 65px;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.8;
  letter-spacing: 4px;
  text-align: center;
  color: #ffffff;
`

export const CardImage = styled("img")({
  marginBottom: 16,
  alignSelf: "center",
  width: "80px",
})

export const CardDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-radius: 4px;
  padding: 24px;
  backdrop-filter: blur(30px);
  box-shadow: -8px 8px 24px 0 rgba(0, 0, 0, 0.48);
  background-color: #2f294f;
`
