import React from "react"
import { Card, CardTitle, CardImage, CardDescription } from "./Card"
import styled from "@emotion/styled"
import List from "./ContentBlock/List"

const CardListTitle = styled.h3`
  padding: 20px;
  max-width: 1300px;
  text-align: center;
  margin: auto;
  @media (min-width: 800px) {
    font-size: ${({ description }: any) =>
      description === "get in touch" ? "20px" : "32px"};
  }
`
interface CardWrapperProps {
  cardTitle: string
}

const CardWrapper = styled.div<CardWrapperProps>`
  grid-template-columns: repeat(1, 1fr);
  display: grid;
  margin: auto;
  max-width: 1350px;
  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 990px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1250px) {
    grid-template-columns: ${({ cardTitle }) =>
      cardTitle === "PLATFORM PILLARS" ? "repeat(4, 1fr)" : "repeat(3, 1fr)"};
  }
`

interface CardListProps {
  cardList: {
    description: string
    title: string
    contentId: string
    cards: {
      nodes: CardProps[]
    }
  }
}

interface CardProps {
  image?: string
  title?: string
  description?: string
  type?: string
  contentId?: string
  body?: {
    children: [any]
  }
  card: {
    title: string
    description: string
    image: string
    type: string
    contentId: string
    body: any
  }
}

const CardContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
  padding: 20px;
  flex-wrap: wrap;
  box-sizing: border-box;
  @media (min-width: 800px) {
  }
`

const Cards = ({ cardList }: CardListProps) => {
  if (!cardList) {
    return null
  }
  return (
    <>
      <CardListTitle description={cardList.description}>
        {cardList.title}
      </CardListTitle>
      <CardWrapper cardTitle={cardList.title}>
        {cardList &&
          cardList.cards.nodes.map((node, index) => {
            return (
              <CardContainer key={index}>
                <Card>
                  {node.title !== "How we work" && (
                    <CardTitle>{node.title}</CardTitle>
                  )}
                  {node.body &&
                    node.body.children &&
                    node.body.children.map((child, contentId: any) => {
                      return (
                        <React.Fragment key={contentId}>
                          <CardImage
                            key={contentId}
                            src={
                              child.image &&
                              child.image.image &&
                              child.image.image.fluid.src
                            }
                            alt={child.image && child.image.alt}
                          />
                          <List {...child} />
                          {child &&
                            child.children &&
                            child.children.map((c: any) => {
                              return (
                                <CardDescription key={contentId}>
                                  {c && c.text}
                                </CardDescription>
                              )
                            })}
                        </React.Fragment>
                      )
                    })}
                </Card>
              </CardContainer>
            )
          })}
      </CardWrapper>
    </>
  )
}

export default Cards
