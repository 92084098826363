import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "@emotion/styled"
import { colors } from "../styles/backgrounds"

import Logo from "../assets/images/dinotechlogo.png"
import hamburger from "../icons/hamburger.svg"
import clear from "../icons/clear.svg"

const StyledHeader = styled.header`
  position: ${({ open }) => (open ? "fixed" : "static")};
  top: 0;
  left: 0;
  right: 0;
  background-color: ${() => colors.backgroundColor};
  background-image: linear-gradient(
    to right,
    ${() => colors.headerColor},
    ${() => colors.backgroundColor},
    ${() => colors.headerColor}
  );
  z-index: 99;
  display: flex;
  height: 80px;
  @media (min-width: 800px) {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.72);
    background: ${() => colors.headerColor};
  }
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 800px) {
    max-width: 1232px;
    padding: 0 40px;
    margin: 0 auto;
  }
`

const LogoLink = styled(Link)``

const Button = styled.button`
  visibility: ${({ visible }: any) => (visible ? "visible" : "hidden")};
  border: none;
  outline: none;
  background: transparent;
  padding: 16px;
  margin: 8px 16px;
  @media (min-width: 800px) {
    display: none;
  }
`

const HeaderMenu = styled.nav`
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  align-items: stretch;
  position: fixed;
  z-index: 99;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background-color: ${() => colors.backgroundColor};
  background-image: linear-gradient(
    to right,
    ${() => colors.headerColor},
    ${() => colors.backgroundColor},
    ${() => colors.headerColor}
  );

  @media (min-width: 800px) {
    display: flex;
    flex-direction: row;
    position: static;
    background: ${() => colors.headerColor};
    padding: 0;
  }
`

const MenuTitle = styled.h2`
  font-family: Avenir, sans-serif;
  font-size: 24px;
  font-weight: normal;

  text-transform: uppercase;
  line-height: 1.46;
  letter-spacing: 4.8px;
  text-align: left;
  color: #5f3461;
  @media (min-width: 800px) {
    display: none;
  }
`

const HeaderLink = styled(Link)`
  font-size: 12px;
  font-weight: 900;

  line-height: 1.33;
  letter-spacing: 1.2px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  padding: 16px;
  @media (min-width: 800px) {
    margin-left: 24px;
    border-bottom: 2px solid transparent;

    &.active {
      border-bottom-color: #ff4977;
    }
  }
`

const RequestDemoLink = styled(Link)`
  font-size: 14px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 1.2px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;

  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.36);
  background-image: linear-gradient(79deg, #ff4a77, #f77874 99%);
  text-transform: uppercase;
  padding: 16px 48px;
  margin-top: 16px;
  @media (min-width: 800px) {
    margin-top: 0;
    margin-left: 24px;
  }
`

const Header = () => {
  const [open, setOpen] = useState()

  return (
    <StyledHeader open={open}>
      <HeaderContainer>
        <Button onClick={() => setOpen(!open)} visible={true}>
          <img src={hamburger} alt="Menu" />
        </Button>
        <LogoLink to="/">
          <img src={Logo} style={{ height: 50 }} alt="Dino Tech" />
        </LogoLink>
        <Button onClick={() => setOpen(false)} visible={open}>
          <img src={clear} alt="Clear" />
        </Button>
        <HeaderMenu open={open}>
          <MenuTitle>Menu</MenuTitle>
          <HeaderLink
            to="/"
            activeClassName="active"
            onClick={() => setOpen(false)}
            visible={open}
          >
            ABOUT US
          </HeaderLink>
          <HeaderLink
            to="/theproduct"
            activeClassName="active"
            onClick={() => setOpen(false)}
            visible={open}
          >
            THE PRODUCT
          </HeaderLink>
          <HeaderLink
            to="/contact"
            activeClassName="active"
            onClick={() => setOpen(false)}
            visible={open}
          >
            CONTACT
          </HeaderLink>
          <RequestDemoLink
            to="/contact"
            activeClassName="active"
            onClick={() => setOpen(false)}
            visible={open}
          >
            Request demo
          </RequestDemoLink>
        </HeaderMenu>
      </HeaderContainer>
    </StyledHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
