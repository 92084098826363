import React from "react"
import styled from "@emotion/styled"
import { Banner, BannerDescription } from "../components/Banner"
import { Text } from "./ContentBlock"

const StyledSection = styled.section`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1300px;
  margin: auto;

  @media (min-width: 800px) {
    padding-top: 20px;
  }
  @media (min-width: 2000px) {
    padding-top: 20px;
    max-width: 1350px;
    justify-content: flex-end;
  }
`

const Image = styled.img`
  width: 100%;
`
const TextContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 1.4px;
  text-align: left;
  color: #ffffff;
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: left;
  }
  h2 {
    font-size: 20px;
    @media (min-width: 800px) {
      font-size: 40px;
    }
  }

  & strong {
    color: #f77874;
    font-weight: normal;
  }
  p {
    margin: 20px auto;
  }
  h4 a {
    background-image: ${({ title }) =>
      title === "Banner"
        ? "linear-gradient(79deg, #ff4a77, #f77874 99%)"
        : "linear-gradient(82deg, #73f5a5, #2bdeff 99%)"};
    font-family: Avenir, sans-serif;
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.36);
    text-transform: uppercase;
    padding: 16px 48px;
  }
  @media (min-width: 800px) {
    text-align: left;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 1.6px;
  }
`

const FlexContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  align-content: center;
  align-items: center;
  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: span 3;
  }
`

const StackTitle = styled.h3`
  font-size: 24px;
  text-align: center;
  margin: 30px auto 0;
  @media (min-width: 800px) {
    text-align: center;
  }
`

const StackContainer = styled.div`
  padding: 0 20px 20px;
`

const StackCard = styled.div`
  padding: 24px 0;
  border-radius: 4px;
  backdrop-filter: blur(30px);
  box-shadow: -8px 8px 24px 0 rgba(0, 0, 0, 0.48);
  background-color: #2f294f;
  margin: 60px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (min-width: 800px) {
    justify-content: space-around;
  }
`

const StackLogo = styled.img`
  height: 64px;
  margin: 8px;
  @media (min-width: 760px) {
    height: 80px;
    margin: 0px;
  }
`

const ImageContainer = styled.div``

export const Section = ({ text }: any) => {
  return (
    <>
      <StyledSection>
        {text?.title === "Banner" && text?.title !== "our stack" ? (
          <Banner>
            <BannerDescription>
              {text?.body?.children?.map((child: any, contentId: string) => {
                return (
                  <TextContainer key={contentId} title={text?.title}>
                    <Text {...child} />
                  </TextContainer>
                )
              }) || null}
            </BannerDescription>
          </Banner>
        ) : (
          <FlexContainer>
            <ImageContainer>
              {text?.title !== "our stack" && text?.title !== "Shape"
                ? text?.body?.children?.map((child: any, contentId: string) => {
                    return (
                      <Image
                        key={contentId}
                        src={child?.image?.image?.fluid?.src || null}
                        alt={child?.image?.alt || null}
                      />
                    )
                  })
                : null}
            </ImageContainer>
            <TextContainer title={text?.title || null}>
              {text?.title !== "our stack"
                ? text?.body?.children?.map((child: any, contentId: string) => {
                    return <Text key={contentId} {...child} />
                  })
                : null}
            </TextContainer>
          </FlexContainer>
        )}
      </StyledSection>
      <StackTitle>{text.title === "our stack" ? text.title : null}</StackTitle>
      <StackContainer>
        {text?.title === "our stack" ? (
          <StackCard title={text?.title || null}>
            {text?.body?.children?.map((child: any, contentId: string) => {
              if (child.__typename === "DinoBlock") {
                return null
              }
              return (
                <ImageContainer key={contentId}>
                  <StackLogo
                    key={child?.contentId || null}
                    src={child?.image?.image?.fluid?.src || null}
                    alt={child?.image?.alt || null}
                  />
                </ImageContainer>
              )
            })}
          </StackCard>
        ) : null}
      </StackContainer>
    </>
  )
}
