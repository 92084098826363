import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import RichTextComponent from "../components/RichText"
import { makeLocalizedUrl, joinPath } from "@dinotechltd/paths"
//import { usePath } from "@dinotechltd/gatsby"
import Layout from "../components/Layout"
import config from "../config"

type Props = {
  path: string
  pathContext: {
    locale: string
    defaultLocale: string
  }
  data: {
    page: {
      title: string
      path: string
      isPrivate: Boolean
      description: string
      keywords: string
      robots?: string
      createdAt: string
      updatedAt: string
      body: {
        children: any
      }
      requiresAuthentication?: boolean
      search?: any
    }
    allPage: {
      nodes: [
        {
          locale: string
          path: string
        }
      ]
    }
  }
}

const Page = ({ data, pathContext, path }: Props) => {
  const { page } = data
  const { locale, defaultLocale } = pathContext
  const canonicalUrl = joinPath(config().BASE_URL(), path)
  //const canonicalUrl = process.env.GATSBY_BASE_URL! + usePath(page.path);
  //   const { submitLayout } = useLayout()
  //   useEffect(() => submitLayout(defaultLayout), [])
  function convertLocale(locale: string) {
    return locale.replace("_", "-")
  }

  const contents = (
    <>
      <Helmet>
        <html lang={convertLocale(locale)} />
        <title>{page.title}</title>
        {page.description && (
          <meta name="description" content={page.description} />
        )}
        {page.keywords && <meta name="keywords" content={page.keywords} />}
        {process.env.GATSBY_ACTIVE_ENV == "production" && page.robots && (
          <meta name="robots" content={page.robots} />
        )}
        <link rel="canonical" key={locale} href={canonicalUrl} />
        {data.allPage.nodes.map(({ locale, path }) => (
          <link
            rel="alternative"
            hrefLang={locale === defaultLocale ? "x-default" : locale}
            key={locale}
            href={makeLocalizedUrl(
              process.env.GATSBY_BASE_URL!,
              locale,
              defaultLocale,
              path
            )}
          />
        ))}
        <script type="application/ld+json" id="ld-webpage">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": `${canonicalUrl}#webpage`,
            url: canonicalUrl,
            inLanguage: locale,
            name: page.title,
            // description,
            isPartOf: `${process.env.GATSBY_BASE_URL}#website`,
            datePublished: page.createdAt,
            dateModified: page.updatedAt,
          })}
        </script>
      </Helmet>
      <Layout>
        <RichTextComponent search={page.search} content={page} />
      </Layout>
    </>
  )
  return contents
}

export default Page

export const query = graphql`
  query($contentId: String!, $locale: String!) {
    page(contentId: { eq: $contentId }, locale: { eq: $locale }) {
      createdAt
      updatedAt
      path
      ...Page
    }
    allPage(filter: { contentId: { eq: $contentId } }) {
      nodes {
        locale
        path
      }
    }
  }
`
