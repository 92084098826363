import React from "react"
import styled from "@emotion/styled"

interface ImagePropsI {
  image: {
    title: string
    description: string
    type: string
    body: {
      children: [
        {
          __typename: string
          image: {
            alt: string
            image: {
              fluid: {
                src: string
              }
            }
          }
        }
      ]
    }
  }
}

const ImageContainer = styled.div`
  margin-top: -110px;
  text-align: ${({ ball }: any) => (ball === "Circle" ? "left" : "center")};
  @media (min-width: 800px) {
    margin-top: ${({ ball }: any) => (ball === "Circle" ? "-110px" : "-210px")};
  }
`

const Image = styled.img`
  max-width: ${({ ball }: any) => (ball === "Circle" ? "100px" : "100%")};
  @media (min-width: 800px) {
    max-width: ${({ ball }: any) => (ball === "Circle" ? "200px" : "600px")};
  }
`

const Shape = ({ image }: ImagePropsI) => {
  return (
    <>
      {image &&
        image.body.children.map(child => {
          if (child.__typename === "DinoBlock") {
            return null
          }
          return (
            <ImageContainer
              key={child.image.alt}
              ball={child && child.image.alt}
            >
              <Image
                ball={child && child.image.alt}
                src={child && child.image.image.fluid.src}
                alt={child && child.image.alt}
              />
            </ImageContainer>
          )
        })}
    </>
  )
}

export default Shape
