import styled from "@emotion/styled"
import latest from "../assets/images/latest_news.png"

export const Banner = styled.div`
  padding: 24px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: -8px 8px 24px 0 rgba(0, 0, 0, 0.48);
  background: url(${latest});
  background-position: 80% 50%;
  @media (max-width: 800px) {
    background: #2f294f;
    align-items: stretch;
    padding: 0;
  }
`

export const BannerDescription = styled.div`
  flex: 0 0 100%;
  padding: 10px;
  font-family: Avenir, sans-serif;
  font-size: 14px;
  font-weight: 500;

  line-height: 1.43;
  letter-spacing: 1.4px;
  color: #ffffff;
  @media (min-width: 800px) {
    flex: 1;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 1.6px;
    max-width: 50%;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
  }
  & strong {
    color: #f77874;
    font-weight: normal;
  }
  p {
    margin-bottom: 20px;
  }
  & a.secondary-button,
  & .secondary-button a {
    font-family: Avenir, sans-serif;
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.36);
    background-image: linear-gradient(79deg, #ff4a77, #f77874 99%);
    text-transform: uppercase;
    padding: 16px 48px;
    @media (max-width: 800px) {
      width: 100%;
      display: block;
    }
  }
`
