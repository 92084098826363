import { Link } from "gatsby"
import React from "react"
import logo from "../assets/images/dinotechlogo.png"
import mga from "../assets/images/malta-gaming-authority.png"

import styled from "@emotion/styled"
import mail from "../icons/mail.svg"
import office from "../icons/office.svg"
import phone from "../icons/phone.svg"

import { colors } from "../styles/backgrounds"
import "typeface-oswald"

const FooterStyle = styled.footer`
  bottom: 0;
  padding: 20px 0;
  background: ${() => colors.footerColor};
  color: white;
  width: 100%;
  p,
  a {
    font-size: 16px;
  }
  @media (min-width: 800px) {
    padding: 20px;
  }
`

const FooterWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  @media (min-width: 800px) {
    max-width: 1300px;
  }
`

const FooterContainer = styled.footer`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
  border-bottom: 1.5px solid #59325f;
  @media (min-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
    justify-items: left;
  }
`

const LinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  a {
    align-items: stretch;
    margin-bottom: 12px;
    color: white;
  }
`

const ContactColumn = styled.div`
  margin-bottom: 40px;
  @media (max-width: 800px) {
    margin-top: 30px;
  }
`

const ColumnTitle = styled.h2`
  margin-bottom: 24px;
`

const ContactContentWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(3 1fr);
  grid-gap: 20px;
`

const ContactContent = styled.div`
  display: grid;
  grid-template-columns: 25px 200px;
  grid-gap: 16px;
  align-items: center;
  p {
    margin: 0;
  }
  @media (min-width: 800px) {
    grid-template-columns: 25px auto;
  }
`

const Icon = styled.img`
  width: 24px;
`

const FooterLogo = styled.img`
  height: 45px;
  margin: 5px 0;
  @media (min-width: 360px) {
    margin: 30px 0;
  }
  @media (min-width: 700px) {
    height: 60px;
    margin: 30px 30px 30px 0;
  }
`

const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media (min-width: 700px) {
    justify-content: center;
  }
`

const MgaText = styled.div`
  margin-top: 30px;
`

const Footer = () => {
  return (
    <FooterStyle>
      <FooterWrapper>
        <FooterContainer>
          <LinksColumn>
            <ColumnTitle>LINKS</ColumnTitle>
            <Link to="/">About Us</Link>
            <Link to="/theproduct">The Product</Link>
            <Link to="/contact">Contact</Link>
          </LinksColumn>
          <ContactColumn>
            <ColumnTitle>CONTACT</ColumnTitle>
            <ContactContentWrapper>
              <ContactContent>
                <Icon src={mail} alt="mail" />
                <p>info@dinotech.com</p>
              </ContactContent>
              <ContactContent>
                <Icon src={phone} alt="phone" />
                <p>+356 21388176</p>
              </ContactContent>
              <ContactContent>
                <Icon src={office} alt="office" />
                <p>
                  The Bastions Office No. 2 Emvin Cremona Street FRN 1281
                  Floriana Malta
                </p>
              </ContactContent>
            </ContactContentWrapper>
          </ContactColumn>
        </FooterContainer>
        <MgaText>
          Dinotech.com is operated by Dinotech Limited a company incorporated in
          Malta, with registration number C 90053, having its registered address
          at The Bastions Office No.2, Emvin Cremona Street, Floriana, FRN1281,
          Malta and is licensed and regulated by the Malta Gaming Authority with
          license number MGA/B2B/909/2021 (Issued on the 7December 2023) to
          supply gaming services under a B2B Critical Gaming Supply Licence.
        </MgaText>
        <LogoContainer>
          <FooterLogo src={logo} alt="logo" />
        </LogoContainer>
      </FooterWrapper>
    </FooterStyle>
  )
}

export default Footer
